import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '../components/layout';
// import SEO from '../components/seo';
import SEORedux from '../components/SeoRedux';

import { PostTitle, PostSubtitle, PostDate, PostBody, PostTag } from '../components/common/posts';

const PostBodyCleaned = styled(PostBody)`
    div.snippet {
        font-size: .8em;
        font-style: italic;
        padding: 1em 0;
        margin: 0 0 2em;
        rgba(0, 0, 0, .5);
        border-style: solid;
        border-color: #dedede;
        border-width: 1px 0;

        p {
            position: relative;
            padding-left: 5.5em;

            &:before {
                font-style: normal;
                position: absolute;
                font-family: 'FontAwesome';
                top: -20px;
                left: 5px;
                content: "\f06a";
                color: #000;
                font-size: 4em;
                color: #ccc;
            }

            margin: 0;
        }
    }
`;

export default function Template({
    data // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds our post data
    const {
        frontmatter: { title, date, tags, subtitle },
        html
    } = markdownRemark;

    return (
        <Layout>
            <SEORedux frontmatter={markdownRemark.frontmatter} isBlogPost />
            <section id="one">
                <div className="blog-post-container">
                    <article>
                        <PostTitle>{title}</PostTitle>
                        <PostSubtitle>{subtitle}</PostSubtitle>
                        <PostDate>
                            <span className="icon fa-calendar" style={{ marginRight: '0.5em' }} />
                            {date}
                        </PostDate>
                        <h3>
                            <span className="icon fa-tags" style={{ marginRight: '0.5em' }} />
                            {tags.map(tag => (
                                <PostTag key={tag}>{tag}</PostTag>
                            ))}
                        </h3>
                        <PostBodyCleaned className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
                    </article>
                </div>
            </section>
        </Layout>
    );
}

Template.propTypes = {
    data: PropTypes.object
};

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                subtitle
                tags
            }
        }
    }
`;
